<template>
  <div class="row">
    <form class="form col-xs-12">
      <div class="form__group">
        <label for="firstName" class="form__label">Promo Code</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g LAUNCHPROMOCODE"
          id="firstName"
          v-model="formData.code"
          name="first_name"
        />
      </div>

      <div class="form__group">
        <label for="firstName" class="form__label">Maximum Redemptions</label>
        <input
          type="number"
          class="form__input"
          placeholder="You can leave the field empty"
          id="maxRedemptions"
          v-model="formData.max_redemptions"
          name="max_redemptions"
        />
      </div>

      <div class="form__group">
        <label for="firstName" class="form__label">Expires At</label>
        <input
          type="date"
          class="form__input"
          placeholder="You can leave the field empty"
          id="expiresAt"
          v-model="formData.expires_at"
          name="expires_at"
        />
      </div>

      <div class="form__group">
        <ca-button @click.native="createPromoCode" :disabled="loading">
          <span>Create</span>
          <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
        </ca-button>
      </div>
    </form>
  </div>
</template>

<script>
import CaButton from "../../../../components/buttons/CaButton";
import axios from "../../../../../axios";
export default {
  name: "CreatePromoCode",
  components: { CaButton },
  data() {
    return {
      loading: false,
      formData: {
        code: "",
        max_redemptions: null,
      },
    };
  },
  methods: {
    createPromoCode() {
      const loading = this.$vs.loading();
      if (this.formData.code === "") {
        this.$toast("Please provide a promo code", {
          position: "top-center",
          type: "error",
        });
        loading.close();
        return;
      }

      const reqData = {
        coupon: this.$route.params.couponId,
        code: this.formData.code,
      };
      console.log(reqData);
      axios
        .post(
          `${this.$store.state.server.requestUri}/admin/coupons/promo-codes`,
          reqData
        )
        .then((res) => {
          this.$toast("Promo code created successfully", {
            type: "success",
            position: "top-center",
          });
          console.log(res);
          loading.close();
          this.$router.push(
            `/coupons/${this.$route.params.couponId}/promo-codes`
          );
        })
        .catch((err) => {
          this.$toast("An error occurred", {
            type: "error",
            position: "top-center",
          });
          console.log(err.response);
          loading.close();
        });
    },
  },
};
</script>

<style scoped></style>
